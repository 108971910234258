<script lang="ts" setup>
import {useLayout} from './composables/layout';
import {Link} from '@inertiajs/vue3';
import AppTopNavigation from '@/PageLayout/AppTopNavigation.vue';
import IconMenu from '@/PageLayout/IconMenu.vue';

const {onMenuToggle, isDarkTheme} = useLayout();

</script>

<template>
    <div class="layout-topbar shadow-sm items-center flex justify-between">
        <div class="layout-topbar-logo-container">
            <button class="layout-menu-button layout-topbar-action" @click="onMenuToggle">
                <i class="fa fa-bars"></i>
            </button>
            <Link class="layout-topbar-logo" href="/">
                <PrimeImage alt="Swytch Logo" width="250" >
                    <template #image>
                        <img v-if="isDarkTheme" alt="Swytch Logo" src="./../../../images/navbar-image-dark.png"
                             width="250"/>
                        <img v-else alt="Swytch Logo" src="./../../../images/navbar-image.png" width="250"/>
                    </template>
                </PrimeImage>

                <span>Tools</span>
            </Link>
        </div>

        <div class="invisible w-0 xl:visible xl:w-auto">
            <app-top-navigation :full-width="false"></app-top-navigation>
        </div>

        <div class="layout-topbar-actions !ml-0">
            <div class="layout-config-menu items-center flex invisible w-0 xl:visible xl:w-auto">
                <icon-menu :expanded="true"></icon-menu>
            </div>
            <div class="layout-config-menu items-center flex visible w-auto xl:invisible xl:w-0">
                <icon-menu :expanded="false"></icon-menu>
            </div>

        </div>
    </div>
</template>
