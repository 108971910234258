
/*
            'status-cancelled': '#f97316',
            'status-complete-for-now': '#078080',
            'status-delivered': '#9831e1',
            'status-processing-abnormal': '#C8001B',
            'status-processing-normal': '#00D188',
            'status-processing-on-hold': '#FFD678',
            'status-unknown': '#C8001B',
 */
export function useStatusColours() {
    const statusMap: {[key: string]: string} = {
        // Orders
        'Cancelled': 'status-cancelled',
        'Locked': 'status-processing-normal',
        'Complete': 'status-delivered',
        'Fulfilled': 'status-delivered',
        'Complete - Not Fulfilled': 'status-complete-for-now',
        'Failed': 'status-processing-abnormal',
        'On Hold': 'status-processing-on-hold',
        'Preorder - Failed': 'status-processing-abnormal',
        'Draft': 'status-complete-for-now',
        'Preorder - Cancelled': 'status-cancelled',
        'Preorder': 'status-processing-normal',

        'Active': 'green',
        'Inactive': 'red',
        'Archived': 'orange',
        'In Development': 'yellow',

        'Fulfilled (Assumed Delivered)': 'status-delivered',
        'Refunded': 'status-complete-for-now',

        // SKUs
        '06-DISCONTINUED': 'status-cancelled',
        '07-WITHDRAWN': 'status-cancelled',
        '03-CURRENT': 'status-processing-normal',
        '04-SPEC CHANGE': 'status-processing-normal',
        '00-PENDING-RELEASE': 'status-processing-normal',
        '02-CURRENT NEW': 'status-processing-normal',
        '05-PENDING DISCONTINUED': 'status-processing-normal',
        '01-NEW': 'status-processing-normal',

        // WEbshop orders
        'completed': 'status-delivered',
        'failed': 'status-processing-abnormal',
        'refunded': 'status-cancelled',
        'cancelled': 'status-cancelled',
        'processing': 'status-processing-normal',

        // Pre orders
        'on-hold': 'status-processing-on-hold',
        'pending': 'status-processing-on-hold',



        // Crowdox order
        'Locked by Ship, Manual': 'status-processing-normal',
        'Locked by Ship': 'status-processing-normal',
        'Locked by Ship, Export': 'status-processing-normal',
        'Locked by Manual': 'status-processing-normal',
        'Locked by Ship, Export, Manual': 'status-processing-normal',
        'Locked by Export': 'status-processing-normal',
        'Completed': 'status-delivered',
        'In Progress': 'status-processing-normal',
        'Locked by Export, Manual': 'status-processing-normal',


        // returns
        'given_to_customer': 'status-processing-normal',
        'created': 'status-processing-normal',
        'complete': 'status-delivered',
        'arrived': 'status-processing-normal',
        'awaiting_refund': 'status-processing-normal',



        // Swytch op orders
        'locked': 'status-processing-normal',
        'not-fulfill': 'status-delivered',
        'draft': 'status-complete-for-now',
        'checkout-draft': 'status-complete-for-now',


        // dispatch requests
        'Uploaded': 'status-processing-normal',
        'Out of Stock': 'status-processing-abnormal',
        'Holding': 'status-processing-on-hold',
        'Upload Error': 'status-processing-abnormal',
        'Pending': 'status-processing-on-hold',




        // Zendesk Ticket
        'deleted': 'status-cancelled',
        'closed': 'status-delivered',
        'open': 'status-processing-normal',
        'new': 'status-processing-normal',
        'hold': 'status-processing-on-hold',
        'solved': 'status-delivered',


        // Dispatches
        'Dispatched': 'status-processing-normal',
        'Awaiting Dispatch': 'status-processing-normal',
        'Processing': 'status-processing-normal',
        'Partially in Stock': 'status-processing-normal',
        'Unknown Stock': 'status-processing-abnormal',
        'Ready to Fulfill': 'status-processing-normal',
        'Deleted?': 'status-cancelled',
        'Abnormal': 'status-processing-abnormal',
        'Delivered': 'status-delivered',
        'Out for Delivery': 'status-processing-normal',
        'Unknown': 'status-processing-abnormal',

        'Approved': 'status-processing-normal',

        // Final mile
        'in_warehouse': 'status-processing-normal',
        'in_transit': 'status-processing-normal',
        'out_for_delivery': 'status-processing-normal',
        'returned_to_sender': 'status-processing-abnormal',
        'delivered': 'status-delivered',
        'picked_up': 'status-processing-normal',
        'courier_notified': 'status-processing-normal',
        'delayed': 'status-processing-abnormal',
        'early': 'status-processing-normal',
        'delivery_instructions_changed': 'status-processing-normal',
        'other': 'status-processing-normal',
        'unknown': 'status-processing-abnormal',
        'charges_due': 'status-processing-abnormal',
        'at_pickup_point': 'status-processing-normal',

        'Not Fulfilled': 'status-processing-abnormal',
        'Manual Override': 'status-complete-for-now',
        'Planned Dispatch': 'status-processing-normal',
        'Dispatching': 'status-processing-normal',
        'Not Required For Fulfilment': 'status-delivered',
        // case DELIVERED = 'Delivered';
        //
        // case NOT_FULFILLED = 'Not Fulfilled';
        //
        // case MANUAL_OVERRIDE = 'Manual Override';
        //
        // case PLANNED_DISPATCH = 'Planned Dispatch';
        //
        // case DISPATCHING = 'Dispatching';
        //
        // case DISPATCHED = 'Dispatched';
        //
        // case NOT_REQUIRED_FOR_FULFILMENT = 'Not Required For Fulfilment';

    };

    const getStatusColour = (status: string) => {
        return statusMap[status] || 'status-unknown';
    };

    return {
        getStatusColour,
    };

}