import moment, {Moment} from 'moment';

export function useDateTime() {

    const asMoment = (date: string|Date): Moment => {
        return moment(date);
    };

    const fromNow = (date: string, allowFullDate: boolean = true) => {
        // If date is more than 11 months in the past or future, return the result of 'fullDate' instead
        if (allowFullDate && Math.abs(asMoment(date).diff(moment(), 'months')) > 11) {
            return fullDate(date);
        }

        return asMoment(date).fromNow();
    };

    const formatDate = (date: string, format: string) => {
        return asMoment(date).format(format);
    };

    const fullDate = (date: string|Date) => {
        return asMoment(date).format('MMMM Do YYYY, h:mm:ss a');
    };

    const fullDateWithoutTime = (date: string) => {
        return asMoment(date).format('MMMM Do YYYY');
    };

    const shortDate = (date: string) => {
        return asMoment(date).format('DD/MM/yyyy');
    };

    const shortReadableDate = (date: string) => {
        return asMoment(date).format('MMMM Do YYYY');
    };

    const shortDateTime = (date: string) => {
        return asMoment(date).format('DD/MM/yyyy HH:mm:ss');
    };

    const charlieDate = (date: string) => {
        return asMoment(date).format('ddd, D MMM YYYY');
    };

    const charlieDateTime = (date: string) => {
        return asMoment(date).format('ddd, D MMM YYYY HH:mm');
    };

    const urlSafeDateTime = (date: string|Date) => {
        return asMoment(date).format('YYYY-MM-DDTHHmmss');
    };

    const fromUrlSafeDateTime = (date: string) : Date => {
        return moment(date, 'YYYY-MM-DDTHHmmss').toDate();
    };

    // Return the date in the format 'Mon Dec 02 2024 00:00:00 GMT+0000 (Greenwich Mean Time)'
    const asDate = (date: string|Date|Moment): Date|null => {
        if(date instanceof Date) {
            return date;
        }

        const d = asMoment(date);
        if(d.isValid()) {
            return d.toDate();
        }
        return null;
    };

    const getDiffInDays = (date1: string, date2: string) => {
        const a = moment(date1);
        const b = moment(date2);
        return a.diff(b, 'days');
    };

    const monthAndYear = (date: string|Date) => {
        return asMoment(date).format('MMMM YYYY');
    };

    const weekMonthAndYear = (date: string|Date) => {
        // Return as w/c 1st December 2024
        let momentDate: Moment = asMoment(date);
        // Set to start of week
        momentDate = momentDate.startOf('isoWeek');
        return 'w/c ' + momentDate.format('D MMMM YYYY');
    };

    return {
        fromNow,
        fullDate,
        fullDateWithoutTime,
        shortDate,
        shortDateTime,
        getDiffInDays,
        shortReadableDate,
        asDate,
        formatDate,
        charlieDate,
        urlSafeDateTime,
        fromUrlSafeDateTime,
        charlieDateTime,
        monthAndYear,
        weekMonthAndYear
    };

}