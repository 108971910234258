<script setup lang="ts">
import {computed, ref} from 'vue';
import {useAuth} from '@/composables/auth';
import {usePage} from '@inertiajs/vue3';
import type {MenuItem} from 'primevue/menuitem';

const toggle = (event) => {
    menu.value.toggle(event);
};

const {initials} = useAuth();

const page = usePage();

const csrf = computed<string>(() => {
    return page.props.csrf;
});

const menu = ref();

const logoutForm = ref(null);

const items = ref<MenuItem[]>([
    {
        label: 'Logout',
        icon: 'fa fa-sign-out',
        command: () => {
            logoutForm.value.submit();
        }
    },
]);

</script>

<template>
    <PrimeButton severity="secondary" @click="toggle" aria-haspopup="true" aria-controls="user_account_overlay_menu">
        {{ initials }}
    </PrimeButton>

    <PrimeMenu ref="menu" id="user_account_overlay_menu" :model="items" :popup="true" />

    <form ref="logoutForm" :action="$route('auth.logout')" method="POST" class="hidden">
        <input type="hidden" name="_token" :value="csrf"/>
    </form>

</template>

<style scoped>

</style>