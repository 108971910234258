<script setup lang="ts">

import {MenuItem} from 'primevue/menuitem';
import {ref} from 'vue';

const props = withDefaults(defineProps<{
    items: MenuItem[],
    rounded?: boolean,
    id?: string
}>(), {
    rounded: false,
    id: 'overlay_menu'
});

const moreMenu = ref(null);

const toggle = (event) => {
    moreMenu.value?.toggle(event);
};

</script>

<template>
    <PrimeButton type="button" severity="secondary" icon="fa fa-ellipsis-vertical" class="mr-2" variant="outlined" @click="toggle" aria-haspopup="true" :aria-controls="props.id" :rounded="props.rounded"/>
    <PrimeMenu ref="moreMenu" :id="props.id" :model="props.items" :popup="true">
        <template #item="{ item, props }">
            <Link v-if="item.url && item.external !== true" :href="item.url"
                  class="flex items-center p-menu-item-link" v-ripple v-tooltip.left="item.hint ?? null">
                <i :class="item.icon" v-if="item.icon"></i>
                <span>{{ item.label }}</span>
            </Link>

            <a v-else-if="item.url" :href="item.url" :target="item.target ?? undefined"
               class="flex items-center p-menu-item-link" v-ripple v-tooltip.left="item.hint ?? null">
                <i :class="item.icon" v-if="item.icon"></i>
                <span>{{ item.label }}</span>
            </a>

            <a v-else v-ripple class="flex items-center" v-bind="props.action" v-tooltip.left="item.hint ?? null">
                <i :class="item.icon" />
                <span>{{ item.label }}</span>
                <PrimeBadge v-if="item.badge" class="ml-auto" :value="item.badge" />
                <span v-if="item.shortcut" class="ml-auto border border-surface rounded bg-emphasis text-muted-color text-xs p-1">{{ item.shortcut }}</span>
            </a>
        </template>
    </PrimeMenu>
</template>

<style scoped>

</style>