<script setup>
import {computed, onMounted, onUnmounted, ref} from 'vue';

import AppMenuItem from './AppMenuItem.vue';
import {router, usePage} from '@inertiajs/vue3';
import SidebarContextMenu from './SidebarContextMenu.vue';
import {useLayout} from '@/PageLayout/composables/layout.js';

const page = usePage();

const {onMenuToggle, isSidebarActive} = useLayout();

const sidebar = computed(() => {
    return page.props.sidebar?.items ?? [];
});


const contextMenuItems = ref([
    {
        label: 'Favorite',
    },
]);

const rightClickSelectedItem = ref(null);

const contextMenu = ref(null);

const showContextMenu = ({event, item}) => {
    rightClickSelectedItem.value = item;
    contextMenu.value.show(event);
};

let closeSidebarEventListener = null;

onMounted(() => {
    closeSidebarEventListener = router.on('start', () => {
        if(isSidebarActive.value) {
            onMenuToggle();
        }
    });
});

onUnmounted(() => {
    if(closeSidebarEventListener !== null) {
        closeSidebarEventListener();
    }
});

</script>

<template>
    <ul class="layout-menu">
        <template v-for="(item, i) in sidebar" :key="item">

            <app-menu-item @on-right-click="showContextMenu" v-if="!item.separator" :item="item" :index="i"></app-menu-item>
            <li v-if="item.separator" class="menu-separator"></li>
        </template>
    </ul>

    <PrimeContextMenu ref="contextMenu" :model="contextMenuItems" @hide="rightClickSelectedItem = null">
        <template #item="{ item, props }">
            <sidebar-context-menu v-if="rightClickSelectedItem !== undefined"
                                  :item-id="rightClickSelectedItem?.id"
                                  :item="item" v-bind="props.action"></sidebar-context-menu>
            <!--            <a v-ripple class="flex align-items-center" v-bind="props.action">-->
            <!--                <i :class="item.icon" />-->
            <!--                <span class="ml-2">{{ item.label }}</span>-->
            <!--                <Badge v-if="item.badge" class="ml-auto" :value="item.badge" />-->
            <!--                <span v-if="item.shortcut" class="ml-auto border-1 surface-border border-round surface-100 text-xs p-1">{{ item.shortcut }}</span>-->
            <!--                <i v-if="item.items" class="pi pi-angle-right ml-auto"></i>-->
            <!--            </a>-->
        </template>
    </PrimeContextMenu>
</template>

<style lang="scss" scoped></style>
