import { createApp, h } from 'vue';
import { createInertiaApp } from '@inertiajs/vue3';
import AppLayout from './Inertia/PageLayout/AppLayout.vue';
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import Pusher from 'pusher-js';
import Echo from 'laravel-echo';
import {resolvePageComponent} from 'laravel-vite-plugin/inertia-helpers';
import {route} from 'ziggy-js';
import {definePreset} from '@primevue/themes';
import {ToastService} from 'primevue';
import ConfirmationService from 'primevue/confirmationservice';
import { Link } from '@inertiajs/vue3';
import * as Sentry from '@sentry/vue';
import help from '@/plugins/help';
import {createPinia} from 'pinia';

window.Pusher = Pusher;

window.Echo = new Echo({
    broadcaster: 'reverb',
    key: import.meta.env.VITE_REVERB_APP_KEY,
    wsHost: import.meta.env.VITE_REVERB_HOST,
    wsPort: import.meta.env.VITE_REVERB_PORT,
    wssPort: import.meta.env.VITE_REVERB_PORT,
    forceTLS: (import.meta.env.VITE_REVERB_SCHEME ?? 'https') === 'https',
    enabledTransports: ['ws', 'wss'],
});


const SwytchToolsTheme = definePreset(Aura, {
    semantic: {
        primary: {
            50: '{blue.50}',
            100: '{blue.100}',
            200: '{blue.200}',
            300: '{blue.300}',
            400: '{blue.400}',
            500: '{blue.500}',
            600: '{blue.600}',
            700: '{blue.700}',
            800: '{blue.800}',
            900: '{blue.900}',
            950: '{blue.950}'
        }
    }
});

const pinia = createPinia();

createInertiaApp({
    progress: {
        // The delay after which the progress bar will appear, in milliseconds...
        delay: 0,

        // The color of the progress bar...
        color: '#29d',

        // Whether to include the default NProgress styles...
        includeCSS: true,

        // Whether the NProgress spinner will be shown...
        showSpinner: false,
    },

    title: title => `${title} - Swytch Tools`,

    resolve: name => {
        // Split at the :: to get the module location, and the page name
        let locationAndPageNameSplit = name.split('::');

        let fullModuleName = locationAndPageNameSplit[0];

        let pagePath = `./Inertia/Pages/${fullModuleName}.vue`;

        if(locationAndPageNameSplit.length > 1) {
            let pageName = locationAndPageNameSplit[1];
            pagePath = `../../${fullModuleName.toLowerCase()}/resources/js/Pages/${pageName}.vue`;
        }

        // const pages should equal all the pages in ./../core/**/resources/js/Pages/*.vue, ./../services/**/resources/js/Pages/*.vue, and ./../tools/**/resources/js/Pages/*.vue
        const page = resolvePageComponent(
            pagePath,
            {
                ...import.meta.glob('../../{core,services,tools}/**/resources/js/Pages/*.vue'),
                ...import.meta.glob('./Inertia/Pages/*.vue'),
            },
        );

        page.then((module) => {
            module.default.layout = module.default.layout || AppLayout;
        });

        return page;
    },
    setup({ el, App, props, plugin }) {
        const app = createApp({ render: () => h(App, props) })
            .use(plugin)
            .use(PrimeVue, {
                locale: {
                    firstDayOfWeek: 1,
                },
                theme: {
                    preset: SwytchToolsTheme,
                    options: {
                        darkModeSelector: '.app-dark'
                    }
                },
                ripple: true,
            })
            .component('Link', Link)
            .use(ToastService)
            .use(ConfirmationService)
            .use(help)
            .use(pinia);

        app.config.globalProperties.$route = route;

        // Only define sentry in production
        // eslint-disable-next-line no-undef
        if(process.env.NODE_ENV === 'production') {
            Sentry.init({
                app: app,
                dsn: 'https://6c0a2f2080f34bbbbdb911efaedd3595@o842162.ingest.us.sentry.io/4505324510314496',

                // eslint-disable-next-line no-undef
                release: APP_VERSION,

                integrations: [
                    Sentry.replayIntegration(),
                    Sentry.feedbackIntegration({
                        colorScheme: 'system',
                        messagePlaceholder: 'What issue have you experienced? What feature would be useful? How can we improve this page?',
                        buttonLabel: 'Send Feedback',
                        showName: false,
                        showEmail: false,
                        formTitle: 'Report a bug or request a feature'
                    })
                ],

                ignoreErrors: [
                    'Request aborted',
                ],
                //
                // Set tracesSampleRate to 1.0 to capture 100%
                // of transactions for performance monitoring.
                // We recommend adjusting this value in production
                tracesSampleRate: 0.10,
                //
                // Capture Replay for 10% of all sessions,
                // plus for 100% of sessions with an error
                replaysSessionSampleRate: 0.01,
                replaysOnErrorSampleRate: 0.10,
            });
        }

        app.mount(el);
    },
});