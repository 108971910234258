<script lang="ts" setup>

import Search from '../../../../core/search/resources/js/InertiaComponents/Search.vue';
import {Link, router, usePage} from '@inertiajs/vue3';
import UserMenu from '@/PageLayout/UserMenu.vue';
import Modal from '@/Components/Modal/Modal.vue';
import {useLayout} from '@/PageLayout/composables/layout';
import {useEnvironment} from '@/composables/environment';
import {useToast} from 'primevue';
import {useSettings} from '@/composables/settings';
import {computed, ref} from 'vue';
import {route} from 'ziggy-js';
import {onKeyStroke} from '@vueuse/core';
import MoreMenuItem from '@/Components/MoreMenu/MoreMenuItem.vue';
import {MenuItem} from 'primevue/menuitem';

const props = defineProps<{
    expanded: boolean;
}>();

const {toggleDarkMode, isDarkTheme} = useLayout();

const {isDeploying, version, environmentName} = useEnvironment();

// const toggleHelp = () => {
//     document.body.classList.toggle('body--show-help');
// };

const toast = useToast();

const settings = useSettings();

const isHidingSensitiveData = computed<boolean>(() => {
    return settings.settingsList.value['hide_sensitive_data'];
});

const toggleSensitiveData = () => {
    router.post(
        route('core.auth.sensitive-data'),
        {
            'hide_sensitive_data': !isHidingSensitiveData.value
        },
        {
            onSuccess: () => {
                toast.add({
                    severity: 'success',
                    summary: 'Sensitive data ' + (
                        isHidingSensitiveData.value ? 'hidden' : 'shown'
                    ),
                    life: 5000
                });
            },
            onError: () => {
                toast.add({
                    severity: 'error',
                    summary: 'Error ' + (
                        isHidingSensitiveData.value ? 'hiding' : 'showing'
                    ) + ' sensitive data',
                    life: 5000
                });
            }
        }
    );

};

onKeyStroke(
    ['s', 'S'],
    (e) => {
        useKeyShortcut(e, () => {
            isSearchVisible.value = true;
        });
    }
);

onKeyStroke(
    ['x', 'X'],
    (e) => {
        useKeyShortcut(e, () => {
            toggleSensitiveData();
        });
    }
);

const useKeyShortcut = (e: KeyboardEvent, callback: () => void) => {
    // Get element currently in focus
    const activeElement = document.activeElement as HTMLElement;
    // if active element is the body
    if (activeElement.tagName === 'BODY') {
        // focus the search input
        callback();
        e.preventDefault();
    }
};

const isSearchVisible = ref<boolean>(false);

const searchQuery = ref<string | null>(null);

const page = usePage();

const sidebar = computed(() => {
    return page.props.topbar?.items ?? [];
});

const mainHeaders = computed<Array<MenuItem>>(() => {
    let headers: Array<MenuItem> = [];

    for(let item of sidebar.value) {
        if(item.visible) {
            headers.push({
                label: item.label,
                icon: item.icon,
                url: item.route ? route(item.route) : null,
                active: item.is_active_route
            });
        }
    }

    return headers;
});

const moreMenuItems = computed<MenuItem[]>(() => [
    ...mainHeaders.value
        .map((item) => {
            return {
                label: item.label,
                icon: 'fa fa-' + item.icon,
                command: () => {
                    if (item.url) {
                        router.get(item.url);
                    }
                    if (item.route) {
                        router.get(route(item.route));
                    }
                }
            };
        }),
    {
        separator: true
    },
    {
        label: 'Search',
        icon: 'fa fa-search',
        command: () => {
            isSearchVisible.value = true;
        }
    },
    {
        label: 'DevOps Links',
        icon: 'fa fa-code',
        command: () => {
            router.get(route('swytch-tools.index'));
        }
    },
    {
        label: 'Settings',
        icon: 'fa fa-cog',
        command: () => {
            router.get(route('core.configuration.index'));
        }
    },
    {
        separator: true
    },
    {
        label: 'Toggle Dark Mode',
        icon: isDarkTheme.value ? 'fa fa-lightbulb' : 'fa fa-moon',
        command: () => {
            toggleDarkMode();
        }
    },
    {
        label: isHidingSensitiveData.value ? 'Show Sensitive Data (x)' : 'Hide Sensitive Data (x)',
        icon: 'fa fa-user-secret',
        command: () => {
            toggleSensitiveData();
        }
    },
    {
        separator: true
    },
    {
        label: version,
        icon: isDeploying.value ? 'fa fa-spinner fa-spin' : 'fa fa-info',
        disabled: true
    }

]);

</script>

<template>
    <modal v-model="isSearchVisible" header="Search Swytch Tools" maximisable>
        <search v-model:query="searchQuery" :compact="true" @finished="isSearchVisible = false"></search>
    </modal>

    <PrimeBadge v-if="environmentName !== 'production'">{{ environmentName }}</PrimeBadge>

    <template v-if="props.expanded">
        <PrimeButton v-tooltip.left="'Search the whole of Swytch Tools (s)'" class="layout-topbar-action"
                     variant="text"
                     @click="isSearchVisible = true">
            <i class="fa fa-search"></i>
        </PrimeButton>


        <span v-help="'The current version of Swytch Tools'" class="flex items-center space-x-2">
            <PrimeProgressSpinner v-if="isDeploying" :strokeWidth="8"
                                  pt:root:class="!h-6 !w-6 items-center"></PrimeProgressSpinner>
            <span>{{ version }}</span>
        </span>

        <!--                <button class="layout-topbar-action show-help:text-[var(&#45;&#45;primary-color)]"  @click="toggleHelp">-->
        <!--                    <i class="fa fa-circle-question"></i>-->
        <!--                </button>-->

        <PrimeButton v-tooltip.left="isHidingSensitiveData ? 'Show sensitive data (x)' : 'Hide sensitive data (x)'" :severity="isHidingSensitiveData ? 'help' : null"
                     class="layout-topbar-action !m-0 !p-0"
                     variant="text"
                     @click="toggleSensitiveData">
            <i class="fa fa-user-secret"></i>
        </PrimeButton>

        <Link :href="$route('swytch-tools.index')">
            <PrimeButton v-tooltip.left="'DevOps links'" class="layout-topbar-action !m-0 !p-0" variant="text">
                <i class="fa fa-code"></i>
            </PrimeButton>
        </Link>

        <PrimeButton v-tooltip.left="'Toggle Dark Mode'" class="layout-topbar-action !m-0 !p-0" variant="text"
                     @click="toggleDarkMode">
            <i :class="['fa', { 'fa-lightbulb': isDarkTheme, 'fa-moon': !isDarkTheme }]"></i>
        </PrimeButton>

        <Link :href="$route('core.configuration.index')">
            <PrimeButton v-tooltip.left="'Settings'" class="layout-topbar-action !m-0 !p-0" variant="text">
                <i class="fa fa-cog"></i>
            </PrimeButton>
        </Link>

        <user-menu></user-menu>
    </template>
    <template v-else>
        <MoreMenuItem :items="moreMenuItems" />

        <user-menu></user-menu>
    </template>
</template>

<style scoped>

</style>